import React from 'react';
import Helmet from 'react-helmet';

// Config
import config from '../../../../config/website';

// Context
import ContextConsumer from '../contextProvider';

const SeoDetails = ({ seoContent, modal, nodeImage, createdNode }) => {
  const { pageTitle, slug, description, featuredImage } = seoContent;

  const pageUrl = `${config.siteUrl}/${slug}/`;
  const pageImage = createdNode
    ? config.siteUrl + nodeImage.fluid.src
    : config.siteUrl + featuredImage.fluid.src;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: pageUrl,
      name: `${pageTitle} - ${config.siteTitle}`
    }
  ];

  schemaOrgJSONLD.push({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: pageUrl,
    name: `${pageTitle} - ${config.siteTitle}`
  });

  return (
    <ContextConsumer>
      {({ menuPanel, bookingPanel, showModal }) => (
        <Helmet>
          {/* General tags */}
          <html lang={config.siteLanguage} />

          <body
            className={
              menuPanel || bookingPanel || showModal || modal ? 'active' : ''
            }
          />

          <title>
            {pageTitle} | {config.siteTitle}
          </title>
          <meta name="image" content={pageImage} />
          <meta name="description" content={description.description} />

          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>

          {/* OpenGraph tags */}
          <meta
            property="og:title"
            content={`${pageTitle} | ${config.siteTitle}`}
          />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:image" content={pageImage} />
          <meta property="og:description" content={description.description} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={config.twitterHandle} />
          <meta
            name="twitter:title"
            content={`${pageTitle} | ${config.siteTitle}`}
          />
          <meta name="twitter:image" content={pageImage} />
          <meta name="twitter:description" content={description.description} />
        </Helmet>
      )}
    </ContextConsumer>
  );
};

export default SeoDetails;
